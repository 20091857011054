(function($) {
    'use strict';

    var drag_elements = $('.drag-scoll');
    var scroll_container = $('.drag-scroll-container');
    var button_left =  scroll_container.find('.scroll-left');
    var button_right =  scroll_container.find('.scroll-right');

    drag_elements.each(function() {
        var element = $(this);
        var container = $(this).closest('.drag-scroll-container');
        var $window = $(window);
        var minOffset = 20;
        var startClientX;
        var startClientY;
        var lastClientX;
        var lastClientY;

        var clickAdded = false;
        var button_left =  container.find('.scroll-left');
        var button_right =  container.find('.scroll-right');

        var active_element = element.find('.active');

        if(active_element.length > 0) {
            var left = active_element.position().left;
            var offset = element.outerWidth() * 0.2;
            element[0].scrollLeft = left - offset;
        }

        button_left.on('click', function() {
            element.animate({ scrollLeft: '-=250' }, 200);
        });

        button_right.on('click', function() {
            element.animate({ scrollLeft: '+=250' }, 200);
        });

        var handleMouseClick = function(event) {
            event.preventDefault();
            event.stopPropagation();
            return false;
        };

        var setDragListeners = function() {
            $window.on('mouseup touchend', handleMouseUp);
            $window.on('mousemove touchmove', handleMouseMove);
        };

        var removeDragListeners = function() {
            setTimeout(function() {
                $window.off('mouseup touchend', handleMouseUp);
                $window.off('mousemove touchmove', handleMouseMove);
                element.off('click', handleMouseClick);
                clickAdded = false;
            }, 10);
        };

        var handleMouseDown = function(event) {
            setDragListeners();
            lastClientX = startClientX = event.clientX;
            lastClientY = startClientY = event.clientY;
            if(event.type === 'touchstart') {
                lastClientX = startClientX = event.touches[0].clientX;
                lastClientY = startClientY = event.touches[0].clientY;
            }
            if(event.type === 'mousedown') {
                event.preventDefault();
                event.stopPropagation();
            }
        };

        var handleMouseUp = function(event) {
            removeDragListeners();
            event.preventDefault();
            event.stopPropagation();
        };

        var handleMouseMove = function(event) {
            var clientX = event.clientX;
            if(event.type === 'touchmove') {
                clientX = event.touches[0].clientX;
            }
            var scrollX = element[0].scrollLeft - (-lastClientX + (lastClientX = clientX));
            if(scrollX <= 0) {
                scrollX = 0;
            }
            if(scrollX >= element[0].scrollWidth - element.outerWidth()) {
                scrollX = element[0].scrollWidth - element.outerWidth();
            }
            //var scrollY = element[0].scrollTop - (-lastClientY + (lastClientY = event.clientY));
            var scrollY = 0;
            if(lastClientX > startClientX + minOffset || lastClientX < startClientX - minOffset || lastClientY > startClientY + minOffset || lastClientY < startClientY - minOffset) {
                element[0].scrollLeft = scrollX;
                element[0].scrollTop = scrollY;
                if(!clickAdded) {
                    element.on('click', handleMouseClick);
                    clickAdded = true;
                }
            }
            //event.preventDefault();
        };

        element.on('scroll', function() {
            var is_left = false;
            var is_right = false;
            var scrollX = element[0].scrollLeft;
            if(scrollX <= 0) {
                scrollX = 0;
                is_left = true;
            }
            if(scrollX >= element[0].scrollWidth - element.outerWidth()) {
                scrollX = element[0].scrollWidth - element.outerWidth();
                is_right = true;
            }
            $('body').trigger('scroll-position', {
                x: scrollX,
                is_left: is_left,
                is_right: is_right,
                element: element
            });
        }).trigger('scroll');

        element.on('mousedown touchstart', handleMouseDown);
    });
    drag_elements.scrollLeft(0);

    var old_x = 0;
    $('body').on('scroll-position', function(event, data) {
        if(data.x !== old_x) {
            old_x = data.x;
            drag_elements.each(function() {
                if(this !== data.element.get(0)) {
                    $(this).scrollLeft(data.x);
                }
            });
        }
        if(data.is_left) {
            button_left.addClass('inactive');
        } else {
            button_left.removeClass('inactive');
        }
        if(data.is_right) {
            button_right.addClass('inactive');
        } else {
            button_right.removeClass('inactive');
        }
    });

}(jQuery));
