(function($) {
    'use strict';

    var $window = $(window);
    var $document = $(document);
    var community_content = $('.community-content-parent');

    if(community_content.length > 0) {
        var is_loading = false;
        var current_page = 1;

        $(window).scroll(function() {
            if($window.scrollTop() + $window.height() + 1000 >= $document.height()) {
                if(!is_loading) {
                    is_loading = true;
                    $.ajax({
                        method: 'POST',
                        url: window.community_posts_url,
                        data: {
                            community_type: window.community_type,
                            type: window.community_page_data.type,
                            filters: window.community_page_data.filters,
                            page: current_page
                        }
                    }).done(function(response) {
                        if(response.success) {
                            var elements = [];
                            if(response.data.length === 0) {
                                return;
                            }

                            var page_one_ad_id = 'div-gpt-ad-1492775422812-1';
                            var page_two_ad_id = 'div-gpt-ad-1492775422812-2';

                            var ad_div = $('<div />', {
                                style: 'width: 300px; height: 250px; position: relative;',
                                class: 'center-block m-b'
                            });
                            ad_div.html('<div class="ad-info"><span>' + window.translations.advertising + '</span></div>');

                            if(window.ad_type === 'dfp') {
                                if(current_page === 1) {
                                    ad_div.attr('id', page_one_ad_id);
                                    elements.push(ad_div.get(0));
                                } else if (current_page === 2) {
                                    ad_div.attr('id', page_two_ad_id);
                                    elements.push(ad_div.get(0));
                                }
                            }

                            $.each(response.data, function(key, element) {
                                elements.push($(element).get(0));
                            });
                            if(window.community_type !== 'webcams') {
                                window.salvattore.appendElements(community_content.get(0), elements);
                            } else {
                                community_content.append(elements);
                            }
                            if(window.ad_type === 'dfp') {
                                if(current_page === 1) {
                                    googletag.cmd.push(function() { googletag.display(page_one_ad_id); });
                                } else if (current_page === 2) {
                                    googletag.cmd.push(function() { googletag.display(page_two_ad_id); });
                                }
                            }
                            current_page++;
                            is_loading = false;
                        }
                    });
                }
            }
        });

        $(community_content).on('click', '.btn-like-alert', function() {
            var alert_id = $(this).data('id');
            var thanks_count_element = $(this).closest('.community-box').find('.thanks-count');
            var type = 'add';
            var old_count = parseInt(thanks_count_element.text());
            if($(this).hasClass('active')) {
                type = 'remove';
                $(this).removeClass('active');
                thanks_count_element.text(old_count - 1);
            } else {
                $(this).addClass('active');
                thanks_count_element.text(old_count + 1);
            }

            $.ajax({
                method: 'POST',
                url: window.community_alerts_thanks_url,
                data: {
                    community_type: window.community_type,
                    alert_id: alert_id,
                    type: type
                }
            });
        });

        $(community_content).on('click', '.btn-report-alert', function() {
            var alert_id = $(this).data('id');
            $.ajax({
                method: 'POST',
                url: window.community_alerts_report_url,
                data: {
                    community_type: window.community_type,
                    alert_id: alert_id
                }
            });
            return false;
        });

        $(community_content).on('click', '.btn-delete-alert', function() {
            var alert_id = $(this).data('id');
            var community_box = $(this).closest('.community-box');
            $.ajax({
                method: 'POST',
                url: window.community_alerts_delete_url,
                data: {
                    community_type: window.community_type,
                    alert_id: alert_id
                }
            }).done(function(response) {
                if(response.success) {
                    community_box.remove();
                }
            });
            return false;
        });

        $(community_content).on('click', '.show-all-comments-link', function() {
            $(this).closest('.comments-container').find('.default-hidden').show();
            $(this).closest('.show-all-comments-holder').hide();
            return false;
        });

        $(community_content).on('submit', '.comment-form', function(event) {
            event.preventDefault();
            var form = $(this);
            var alert_id = form.find('[name="alert_id"]').val();
            var text = form.find('[name="text"]').val();
            if(text === '') {
                return false;
            }
            $.ajax({
                method: 'POST',
                url: form.attr('action'),
                data: {
                    community_type: window.community_type,
                    alert_id: alert_id,
                    text: text
                }
            }).done(function(response) {
                if(response.success) {
                    form.closest('.community-box').find('.comments').append(response.data.html);
                    form.find('[name="text"]').val('');
                }
            });
            return false;
        });
    }

    var show_share_step = function(step) {
        $('.share-step.step-' + (step - 1)).addClass('finished');
        $('.share-step.step-' + step).addClass('active');
    };

    var upload_file = null;
    $('.upload-file-form').on('submit', function(event) {
        event.preventDefault();
        var form = $(this);
        var text = form.find('[name="text"]').val();
        var location_element = form.find('[name="address"]');
        var address = location_element.val();
        var coordinates = location_element.data('lon') + ' ' + location_element.data('lat');

        var share_twitter = form.find('[name="share_twitter"]').prop('checked');
        var share_facebook = form.find('[name="share_facebook"]').prop('checked');
        var share_google = form.find('[name="share_google"]').prop('checked');

        var data = new FormData();
        data.append('image', upload_file);
        data.append('text', text);
        data.append('address', address);
        data.append('coordinate', coordinates);
        data.append('share_twitter', share_twitter);
        data.append('share_facebook', share_facebook);
        data.append('share_google', share_google);

        $.ajax({
            method: 'POST',
            url: form.attr('action'),
            data: data,
            contentType: false,
            processData: false
        }).done(function(response) {
            if(response.success) {
                window.location.reload();
            }
        });
        return false;
    });

    $('.upload-file-form [name="text"]').on('change', function() {
        if($('.upload-file-form .js-search-location').val() !== '') {
            show_share_step(3);
        }
    });

    $.fn.generateImageDropZone = function() {
        var holder = $(this);
        var preview = holder.find('.preview-img');
        var upload_info = holder.find('.upload-info');
        var file_upload_element = holder.find('.file-upload-element');

        var reader = new FileReader();
        reader.onload = function(e) {
            preview.attr('src', e.target.result);
            preview.show();
            upload_info.hide();
        };

        var handleFiles = function(files) {
            upload_file = files[0];
            reader.readAsDataURL(upload_file);
            show_share_step(2);
        };

        file_upload_element.on('change', function() {
            handleFiles(this.files);
        });

        var dragenter = function(e) {
            e.stopPropagation();
            e.preventDefault();
        };

        var dragover = function(e) {
            e.stopPropagation();
            e.preventDefault();
        };

        var drop = function(evt) {
            var e = evt.originalEvent;
            e.stopPropagation();
            e.preventDefault();

            var dt = e.dataTransfer;
            var files = dt.files;

            handleFiles(files);
        };

        holder.on('dragenter', dragenter);
        holder.on('dragover', dragover);
        holder.on('drop', drop);
    };
    $('.drop-file-box').generateImageDropZone();

    $('.upload-file-form .search-result').on('click', 'a', function(event) {
        event.preventDefault();
        var address = $('.upload-file-form [name="address"]');
        var element = $(this);
        address.val(element.text());
        address.data('lat', element.data('lat'));
        address.data('lon', element.data('lon'));
        $(this).closest('.search-result').hide();

        if($('.upload-file-form [name="text"]').val() !== '') {
            show_share_step(3);
        }
    });

    $('.community-account-form').on('submit', function(event) {
        event.preventDefault();
        var form = $(this);

        var data = new FormData();
        $.each(form.serializeArray(), function(key, value) {
            data.append(value.name, value.value);
        });

        var tos = form.find('[name="tos"]');
        if(tos.length > 0) {
            if(tos.prop('checked') !== true) {
                form.find('.form-alert').html(tos.data('error')).show();
                return false;
            }
        }

        var display_name = form.find('[name="display_name"]');
        if(display_name.length > 0) {
            if(display_name.val() === '') {
                form.find('.form-alert').html(display_name.data('error')).show();
                return false;
            }
        }

        var image = form.find('[name="image"]');
        var cover_image = form.find('[name="cover_image"]');
        if(image.length > 0) {
            data.append('image', image[0].files[0]);
        }

        if(cover_image.length > 0) {
            data.append('cover_image', cover_image[0].files[0]);
        }

        $.ajax({
            method: 'POST',
            url: form.attr('action'),
            data: data,
            contentType: false,
            processData: false
        }).done(function(response) {
            if(response.success) {
                window.location.reload();
            } else {
                form.find('.form-alert').html(response.error).show();
            }
        });
        return false;
    });

    $('.follow-modal').each(function() {
        var modal = $(this);
        var follower_list = modal.find('.follower-list');
        var elements = follower_list.data('elements');
        var template = $(modal.find('.follower-row-template').html());
        var found_elements = [];
        var current_index = 0;

        var addNumberOfListElement = function(count) {
            var max_break = current_index + count;
            var i = 0;
            for(i = current_index; i < max_break; i++) {
                addListElement(i);
            }
            current_index = i;
        };

        var addListElement = function(index) {
            if(typeof found_elements[index] === 'undefined') {
                return;
            }
            var value = found_elements[index];
            var element = $(template.clone());
            element = $('<li/>', { html: element });
            element.find('.user-img-circle').css('background-image', 'url(' + value.img + ')');
            element.find('.search-text').html(value.text);
            if(value.is_self) {
                element.find('.follow-btn-holder').remove();
            }
            if(value.i_follow) {
                element.find('.follow-btn-holder').addClass('i-follow');
            }
            element.find('.btn-follow, .btn-unfollow').attr('data-id', value.user_id).data('id', value.user_id);
            follower_list.append(element);
        };

        var loadSearch = function() {
            var input_element = modal.find('.search-users-input');
            var search_string = input_element.val().toLowerCase();
            found_elements = [];
            $.each(elements, function(key, value) {
                var find_string = value.text.replace(/(<([^>]+)>)/ig,'').toLowerCase();
                if(find_string.indexOf(search_string) !== -1) {
                    found_elements.push(value);
                }
            });
            current_index = 0;
            follower_list.html('');
            follower_list.scrollTop(0);
            addNumberOfListElement(10);
        };

        follower_list.on('scroll', function () {
            var height = follower_list.prop('scrollHeight');
            if(follower_list.scrollTop() + follower_list.height() + 50 >= height) {
                addNumberOfListElement(10);
            }
        });

        modal.on('show.bs.modal', function () {
            loadSearch();
        });

        modal.find('.search-users-input').on('keyup change', function() {
            loadSearch();
        });
    });

    $(document).on('click', '.btn-follow, .btn-unfollow', function(event) {
        event.preventDefault();
        var follow = $(this).hasClass('btn-follow');
        var btn = $(this);
        $.ajax({
            method: 'POST',
            url: $(this).data('url'),
            data: {
                user_id: $(this).data('id'),
                current_user_id: $(this).data('current-id'),
                follow: follow
            }
        }).done(function(response) {
            if(response.success) {
                if(follow) {
                    btn.parent().addClass('i-follow');
                } else {
                    btn.parent().removeClass('i-follow');
                }
            }
        });
        return false;
    });

    $('.share-post-btn').on('click', function() {
        $('.share-post-form').find('[name="post_id"]').val($(this).data('id'));
    });

    $('.share-post-form').on('submit', function(event) {
        event.preventDefault();
        var form = $(this);

        var post_id = form.find('[name="post_id"]').val();
        var share_twitter = form.find('[name="share_twitter"]').prop('checked');
        var share_facebook = form.find('[name="share_facebook"]').prop('checked');
        var share_google = form.find('[name="share_google"]').prop('checked');

        $.ajax({
            method: 'POST',
            url: form.attr('action'),
            data: {
                post_id: post_id,
                share_twitter: share_twitter,
                share_facebook: share_facebook,
                share_google: share_google
            }
        }).done(function(response) {
            if(response.success) {
                $('#share-post-modal').modal('hide');
            }
        });
        return false;
    });

}(jQuery));
