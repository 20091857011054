(function($) {
    'use strict';

    var $window = $(window);
    var $document = $(document);
    var video_content_holder = $('#video-content-holder');

    if(video_content_holder.length > 0) {
        var is_loading = false;
        var current_page = 1;
        var page_data = window.video_posts_data;

        $(window).scroll(function() {
            if($window.scrollTop() + $window.height() + 1000 >= $document.height()) {
                if(!is_loading) {
                    is_loading = true;
                    var data = {
                        page: current_page
                    };
                    if(typeof page_data.category !== 'undefined') {
                        data.category = page_data.category;
                    }
                    $.ajax({
                        method: 'POST',
                        url: window.video_posts_url,
                        data: data
                    }).done(function(response) {
                        if(response.success) {
                            if(response.data.length === 0) {
                                return;
                            }
                            video_content_holder.append(response.data);
                            current_page++;
                            is_loading = false;
                        }
                    });
                }
            }
        });
    }

    $('#video-category-select').on('change', function() {
        var selected_option = $(this).find('option:selected');
        window.location.href = selected_option.data('url');
    });

}(jQuery));
