(function($) {
    'use strict';

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    var append_elements = $('.js-append-element');

    $('.community-content').magnificPopup({
        delegate: '.gallery-link',
        type: 'image'
        /*
        gallery: {
            enabled: true,
            navigateByImgClick: true,
            preload: [0,1]
        }
        */
    });

    $('.news-slider').slick({
        dots: true,
        prevArrow: '<button type="button" class="slider-prev"><svg class="svg-icons-play-dims" style="-webkit-transform: rotate(180deg); -moz-transform: rotate(180deg); transform: rotate(180deg);"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="' + window.config.svg_sprite_url + '#play"></use></svg></button>',
        nextArrow: '<button type="button" class="slider-next"><svg class="svg-icons-play-dims "><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="' + window.config.svg_sprite_url + '#play"></use></svg></button>'
    });

    var main_slider = $('.main-slider');
    main_slider.on('init', function() {
        var container = $(this).closest('.main-slider-container');
        container.find('.arrow-holder-left .slider-next').appendTo(container.find('.arrow-holder-right'));
    }).slick({
        autoplay: true,
        autoplaySpeed: 6000,
        dots: true,
        appendArrows: $('.main-slider-container .arrow-holder-left'),
        appendDots: $('.main-slider-container .dots-holder'),
        prevArrow: '<button type="button" class="slider-prev"><svg class="svg-icons-play-dims arrow-icon" style="-webkit-transform: rotate(180deg); -moz-transform: rotate(180deg); transform: rotate(180deg);"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="' + window.config.svg_sprite_url + '#play"></use></svg></button>',
        nextArrow: '<button type="button" class="slider-next"><svg class="svg-icons-play-dims arrow-icon"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="' + window.config.svg_sprite_url + '#play"></use></svg></button>'
    });

    $('.main-slider-container').on('click', '.slider-prev', function() {
        var currentSlide_index = main_slider.slick('slickCurrentSlide') + 1;
        var slides = main_slider.get(0).slick.$slides;
        if(currentSlide_index > slides.length - 1) {
            currentSlide_index = 0;
        }
        var currentSlide = $(slides.get(currentSlide_index));
        window.ga('send', 'event', { eventCategory: 'Homescreen', eventAction: currentSlide.data('title'), eventLabel: 'Left arrow'});
    }).on('click', '.slider-next', function() {
        var currentSlide_index = main_slider.slick('slickCurrentSlide') - 1;
        var slides = main_slider.get(0).slick.$slides;
        if(currentSlide_index < 0) {
            currentSlide_index = slides.length - 1;
        }
        var currentSlide = $(slides.get(currentSlide_index));
        window.ga('send', 'event', { eventCategory: 'Homescreen', eventAction: currentSlide.data('title'), eventLabel: 'Right arrow'});
    });

    $('.close-tour').on('click', function(){
        window.setCookie('tour_closed', true, 365);
        $(this).closest('.tour-info-overlay').remove();
        return false;
    });

    $('.lang-switch').on('click', function(){
        window.setCookie('locale', $(this).data('lang'), 30);
    });

    var padding_element = $('.main-content-container');

    var my_locations = $('.location-search-container.search-my-locations');
    var location_search = $('.location-search-container.location-search');
    $(document).on('mousedown touchstart', function() {
        my_locations = $('.location-search-container.search-my-locations');
        location_search = $('.location-search-container.location-search');
    });
    $(document).on('mouseup touchend', function() {
        setTimeout(function() {
            if(my_locations.length > 0) {
                $('.bg-darkener').hide();
                my_locations.removeClass('search-my-locations');
                $('.search-overlay-container').removeClass('search-my-locations');
                my_locations.find('.my-locations').hide();
            }
            if(location_search.length > 0) {
                location_search.removeClass('location-search');
                location_search.find('.my-locations').hide();
            }
        }, 1);
    });

    $('.video-modal').on('shown.bs.modal', function () {
        $(this).find('video').get(0).play();
    }).on('hidden.bs.modal', function () {
        var video = $(this).find('video').get(0);
        video.pause();
        video.currentTime = 0;
    });

    $('.js-tour-step').on('click', function() {
        $('.tour-info-overlay').hide();
        $('.tour-info-step-' + $(this).data('next-step')).show();
        return false;
    });

    $('.video-modal-link').on('click', function() {
        $(this).find('video').get(0).pause();
    });

    $(document).ready(function() {
        $('.my-pando-chart').each(function() {
            var chart = $(this);
            chart.pandoChart($(this).data('options'));
            $.each($(this).data('is-active'), function(i, isActive) {
                if(isActive) {
                    chart.find('.psc-grid-bg-col-' + i).addClass('active');
                }
            });
        });
    });

    $('.forecast-header-fixed').each(function() {
        var element = $(this);
        var element_2 = $('.forecast-sub-header-fixed');
        var padding_element = $('.forecast-fixed-content');
        var offsetTop = function() {
            return element.offset().top - $('.header-container').outerHeight();
        };
        var element_top = offsetTop();

        var element2_offsets = [];
        element_2.each(function() {
            element2_offsets.push($(this).offset());
        });

        var generateNewPosition = function() {
            var scroll_top = $(document).scrollTop();
            if(element.css('position') === 'fixed') {
                setElementRelative();
                element_top = offsetTop();
                setElementFixed();
            } else {
                element_top = offsetTop();
            }
            $(document).scrollTop(scroll_top);
        };

        $(document).on('ad-loaded', function() {
            generateNewPosition();
        });

        $(window).on('resize', function() {
            generateNewPosition();
        });

        var setElementFixed = function() {
            var element_height = element.outerHeight();
            var element_2_height = element_2.outerHeight();
            element_2.each(function(key) {
                element_2.css('top', $('.header-container').outerHeight() + element_height);
                $(this).css('left', element2_offsets[key].left);
                $(this).css('width', $(this).outerWidth());
            });
            if(element.hasClass('forecast-header-fixed')) {
                element.css('left', '20px');
                element.css('top', $('.header-container').outerHeight());
                element.css('width', 'calc(100% - 360px)');
            }

            padding_element.css('padding-top', element_height + element_2_height);
            element.css('position', 'fixed');
            element_2.css('position', 'fixed');
        };

        var setElementRelative = function() {
            element.css('position', 'relative');
            element.css('top', '0');
            element.css('left', '0');
            element.css('width', '');
            element_2.css('position', 'relative');
            element_2.css('top', '0');
            element_2.css('left', '0');
            element_2.css('width', '');
            padding_element.css('padding-top', 0);
        };

        $(window).on('scroll', function() {
            var scrollTop = $(window).scrollTop();
            if(scrollTop >= element_top) {
                setElementFixed();
            } else {
                setElementRelative();
            }
        });
    });

    $('.forecast-sidebar').each(function() {
        var element = $(this);
        var offsetTop = function() {
            return element.offset().top - $('.header-container').outerHeight() -20;
        };
        var element_top = offsetTop();

        $(document).on('ad-loaded', function() {
            if(element.css('position') === 'fixed') {
                setElementRelative();
                element_top = offsetTop();
                setElementFixed();
            } else {
                element_top = offsetTop();
            }
        });

        var setElementFixed = function() {
            element.css('top', $('.header-container').outerHeight() + 20);
            element.css('position', 'fixed');
        };

        var setElementRelative = function() {
            element.css('position', 'relative');
            element.css('top', '0');
            element.css('width', '');
        };

        $(window).on('scroll', function() {
            var scrollTop = $(window).scrollTop();
            if(scrollTop >= element_top) {
                setElementFixed();
            } else {
                setElementRelative();
            }
        });
    });

    append_elements.each(function() {
        var element = $(this);
        var element_top = element.position().top;

        $(document).on('ad-loaded', function() {
            if(element.css('position') === 'fixed') {
                setHeaderRelative();
                element_top = element.position().top;
                setHeaderFixed();
            } else {
                element_top = element.position().top;
            }
        });

        $(window).on('resize', function() {
            setTimeout(function() {
                var old_position = element.css('position');
                var old_padding = padding_element.css('padding-top');
                element.css('position', 'static');
                padding_element.css('padding-top', 0);
                element_top = element.position().top;
                element.css('position', old_position);
                padding_element.css('padding-top',  old_padding);
            }, 10);
        });

        var setHeaderFixed = function() {
            if(element.hasClass('js-use-padding')) {
                padding_element.css('padding-top', element.outerHeight());
            }
            element.css('position', 'fixed');
        };

        var setHeaderRelative = function() {
            element.css('position', 'relative');
            padding_element.css('padding-top', 0);
        };

        $(window).on('scroll', function() {
            var scrollTop = $(window).scrollTop();
            if(element.hasClass('js-append-element-header') && $(window).width() < 768) {
                setHeaderFixed();
                return;
            }
            if(scrollTop >= element_top) {
                setHeaderFixed();
            } else {
                setHeaderRelative();
            }
        });
    });
    $(window).trigger('scroll');

    var nav_open_padding = 0;
    $('.navbar-collapse').on('show.bs.collapse', function () {
        $(this).addClass('show-nav');
        $(this).addClass('show-nav-bg');
        var element = $('.js-append-element');
        element.addClass('is-fixed');
        nav_open_padding = padding_element.css('padding-top');
        padding_element.css('padding-top', element.outerHeight());
    }).on('hide.bs.collapse', function () {
        $(this).removeClass('show-nav');
    }).on('hidden.bs.collapse', function () {
        $(this).removeClass('show-nav-bg');
        $('.js-append-element').removeClass('is-fixed');
        padding_element.css('padding-top', nav_open_padding);
    });

    $('.settings-radio').on('change', function() {
        var url = $(this).data('url');
        var label = $(this).val();
        var action = $(this).attr('name');

        switch(label) {
            case 'C':
                label = 'Celsius';
                break;
            case 'F':
                label = 'Fahrenheit';
                break;
            default:
                break;
        }
        switch(action) {
            case 'unit_temp':
                action = 'Temperature';
                break;
            case 'unit_rain':
                action = 'Precipitation';
                break;
            case 'unit_wind':
                action = 'Wind speed';
                break;
            case 'unit_dec':
                action = 'Decimal places';
                break;
            case 'unit_pressure':
                action = 'Pressure';
                break;
            default:
                break;
        }
        window.ga('send', 'event', { eventCategory: 'Settings', eventAction: action, eventLabel: label});

        $.ajax({
            method: 'POST',
            url: url,
            data: {
                type: $(this).attr('name'),
                value: $(this).val()
            }
        }).done(function(response) {
            if(response.success) {
                window.location.reload();
            }
        });
        return false;
    });

    $.fn.searchLocation = function() {
        var no_results_info = $(this).data('no-results');
        var url = $(this).data('url');
        var container = $(this).closest('.js-location-search-container');
        var search_container = $(this).closest('.search-container');
        var result_container = search_container.find('.search-result');
        var timeout = null;
        var template = container.find('.search-location-loading');

        var old_val = '';
        var xhr = null;
        $(this).on('change keyup', function() {
            window.setCookie('tour_closed', true, 365);
            var val = $(this).val();
            if(val === old_val) {
                return;
            }
            if(val.length < 2) {
                if(timeout !== null) {
                    clearTimeout(timeout);
                }
                container.removeClass('location-search');
                result_container.hide();
                return;
            }
            if(val !== '') {
                if(timeout !== null) {
                    clearTimeout(timeout);
                }
                var loading_row = $('<li/>', {
                    style: 'padding: 20px 0',
                    class: 'text-center'
                });
                container.addClass('location-search');
                loading_row.append(template.html());
                result_container.html('');
                result_container.append(loading_row);
                result_container.show();
                timeout = setTimeout(function() {
                    if(xhr !== null) {
                        xhr.abort();
                    }
                    xhr = $.ajax({
                        method: 'POST',
                        url: url,
                        data: {
                            search: val
                        }
                    }).done(function(response) {
                        result_container.html('');
                        if(response.success) {
                            if(response.data.length === 0) {
                                result_container.append('<li><a href="#">' + no_results_info + '</a></li>');
                            }
                            $.each(response.data, function(key, value) {
                                result_container.append('<li><a href="' + value.url + '" data-id="' + value.id + '" data-lat="' + value.coordinates.lat + '" data-lon="' + value.coordinates.lon + '" onClick="ga(\'send\', \'event\', { eventCategory: \'Location\', eventAction: \'Search\', eventLabel: \'' + value.coordinates.lon + ' ' + value.coordinates.lat + '\'});" >' + value.name + '</a></li>');
                            });
                        }
                    });
                }, 300);
            }
            old_val = val;
        });
    };

    $('.js-search-location').each(function() {
        $(this).searchLocation();
    });

    $('.js-my-locations').each(function() {
        var url = $(this).data('url');
        var search_overlay_container = $(this).parents('.search-overlay-container');
        var container = $(this).closest('.js-location-search-container');
        var result_container = container.find('.my-locations');
        var template = container.find('.my-location-template');
        var location_data = null;

        var checkLocationsAreRemoveAble = function() {
            if(result_container.find('li').length <= 1) {
                result_container.find('.remove-location').hide();
            }
        };

        var showLocations = function(locations, show) {
            if(typeof show === 'undefined') {
                show = true;
            }
            var header = result_container.find('.my-locations-header');
            result_container.html('');
            result_container.append(header);
            if(show) {
                result_container.show();
            }
            $.each(locations, function(key, value) {
                var holder = $('<li />');
                var element = $(template.html());
                element.find('.name').html(value.name).attr('href', value.link).attr('data-id', value.id).attr('onClick', "ga('send', 'event', { eventCategory: 'Location', eventAction: 'Pick from favorites', eventLabel: '" + value.coordinates + "'});");
                element.find('.weather-icon').html(value.weather.icon);
                element.find('.weather-temp').html(value.weather.temp);
                element.find('.remove-location').attr('data-id', value.location_id);
                holder.append(element);
                result_container.append(holder);
            });
            checkLocationsAreRemoveAble();
        };

        var updateLocations = function(show) {
            if(typeof show === 'undefined') {
                show = true;
            }
            $.ajax({
                method: 'POST',
                url: url
            }).done(function(response) {
                if(response.success) {
                    location_data = response.data;
                    showLocations(location_data, show);
                }
            });
        };

        $(window).on('update-locations', function() {
            updateLocations(false);
        });

        $(this).on('click', function() {
            if(!container.hasClass('search-my-locations')) {
                $('.bg-darkener').show();
                result_container.show();
                container.addClass('search-my-locations');
                search_overlay_container.addClass('search-my-locations');
                if(location_data === null) {
                    updateLocations();
                } else {
                    showLocations(location_data);
                }
            } /* else {
                container.removeClass('search-my-locations');
                search_overlay_container.removeClass('search-my-locations');
                result_container.hide();
                $('.bg-darkener').hide();
            } */
        });

        $(this).closest('.js-location-search-container').on('click', '.remove-location', function() {
            var holder = $(this).closest('li');
            var url = $(this).data('url');
            var location_id = $(this).data('id');
            $.ajax({
                method: 'POST',
                url: url,
                data: {
                    location_id: location_id
                }
            }).done(function(response) {
                if(response.success) {
                    holder.remove();
                    location_data = null;
                    checkLocationsAreRemoveAble();
                    updateLocations(false);
                }
            });
        });
    });

    $('body').on('click', '.toggle-favorite-location', function() {
        var location_id = $(this).data('location-id');
        var lat = $(this).data('lat');
        var lon = $(this).data('lon');
        var add_url = $(this).data('add-url');
        var remove_url = $(this).data('remove-url');
        var poi_name = $(this).data('name');
        var button = $(this);
        if(location_id !== '') {
            $.ajax({
                method: 'POST',
                url: remove_url,
                data: {
                    location_id: location_id
                }
            }).done(function(response) {
                if(response.success) {
                    button.removeClass('active');
                    $(window).trigger('update-locations');
                }
            });
        } else {
            $.ajax({
                method: 'POST',
                url: add_url,
                data: {
                    lat: lat,
                    lon: lon,
                    name: poi_name
                }
            }).done(function(response) {
                if(response.success) {
                    button.addClass('active');
                    $(window).trigger('update-locations');
                }
            });
        }
        return false;
    });

    var compareViewRedirect = function() {
        var location_ids = [];
        $('.compare-view .js-search-location').each(function() {
            var id = $(this).data('id');
            if(typeof id !== 'undefined') {
                location_ids.push(id);
            } else {
                location_ids.push('');
            }
        });
        var redirect_url = window.compare_url.replace('__locations__', location_ids.join(','));
        redirect_url = redirect_url.replace('__locations__', '');
        window.location.href = redirect_url;
    };

    $('.compare-view .search-result').on('click', 'a', function(event) {
        event.preventDefault();
        var selected_location_ids = $(this).data('id');
        $(this).closest('.js-location-search-container').find('.js-search-location').data('id', selected_location_ids);
        compareViewRedirect();
        return false;
    });

    $('.compare-view .my-locations').on('click', 'a.name', function(event) {
        event.preventDefault();
        var selected_location_ids = $(this).data('id');
        $(this).closest('.js-location-search-container').find('.js-search-location').data('id', selected_location_ids);
        compareViewRedirect();
        return false;
    });

    var search_tab_index = 0;
    $(document).on('keydown', '.js-location-search-container', function(event) {
        var container = $(this);
        var code = event.keyCode || event.which;
        var search_container = container.find('.search-result');
        var links = search_container.find('a');
        if(links.length > 0) {
            if(code === 40) {
                search_tab_index++;
                if(search_tab_index >= links.length) {
                    search_tab_index = links.length;
                }
                links.get(search_tab_index - 1).focus();
                return false;
            } else if(code === 38) {
                search_tab_index--;
                if(search_tab_index < 1) {
                    search_tab_index = 1;
                }
                links.get(search_tab_index - 1).focus();
                return false;
            } else {
                search_tab_index = 0;
            }
        }
    });

    $(document).on('keydown', function(event) {
        var code = event.keyCode || event.which;
        if(code === 27) {
            $('.fullscreen-holder-container.is-fullscreen').removeClass('is-fullscreen');
            $(window).trigger('resize');
        }
    });

    $('.sticky-video').each(function() {
        if($(window).width() <= 767) {
            var element = $(this);
            var element_2 = $('.category-sticky');
            var header_height = $('.header-container').outerHeight();

            var offsetTop = function() {
                return Math.round(element.offset().top - header_height + element.outerHeight());
            };

            var offsetTop2 = function() {
                return Math.round(element_2.offset().top - header_height - element.outerHeight() + element_2.outerHeight());
            };

            var element_top = offsetTop();
            var element_height = element.outerHeight();

            var element_2_top = offsetTop2();
            var element_2_height = element_2.outerHeight();

            var setElementFixed = function() {
                if(!element.hasClass('is-sticky')) {
                    $('.video-page-header').css('padding-top', element_height);
                    element.addClass('is-sticky');
                    element_2_top = offsetTop2();
                }
            };

            var setElementRelative = function() {
                $('.video-page-header').css('padding-top', 0);
                element.removeClass('is-sticky');
            };

            var setElement2Fixed = function() {
                $('.video-page-header-top').css('padding-top', element_2_height);
                element_2.css('top', element.outerHeight() + header_height);
                element_2.addClass('is-sticky');
            };

            var setElement2Relative = function() {
                $('.video-page-header-top').css('padding-top', 0);
                element_2.removeClass('is-sticky');
            };

            $(window).on('scroll', function() {
                var scrollTop = $(window).scrollTop();
                if(scrollTop >= element_top) {
                    setElementFixed();
                } else {
                    setElementRelative();
                }

                if(scrollTop >= element_2_top) {
                    setElement2Fixed();
                } else {
                    setElement2Relative();
                }
            }).trigger('scroll');
        }
    });

    $('body').on('click', '.sticky-video.is-sticky', function() {
        $('body').pandoScrollTo(0);
    });

}(jQuery));

(function($) {
    'use strict';

    var button = document.getElementById('cookie-close-btn');
    var banner = document.getElementById('cookie-banner');
    var bannerSpacer = document.getElementById('cookie-spacer');

    if(getCookie('cookies_accepted') !== 'yes') {
        banner.style.display = 'block';
        bannerSpacer.style.display = 'block';
        $(bannerSpacer).height($(banner).outerHeight());
    }

    var setCookie = function(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = 'expires=' + d.toUTCString();
        document.cookie = cname + '=' + cvalue + '; ' + expires + ';path=/';
    };

    button.addEventListener('click', function(event) {
        event.preventDefault();
        setCookie('cookies_accepted', 'yes', 365);
        banner.style.display = 'none';
        bannerSpacer.style.display = 'none';
        return false;
    });

    function getCookie(cname) {
        var name = cname + '=';
        var ca = document.cookie.split(';');
        for(var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while(c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if(c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return '';
    }
}(jQuery));
