(function($) {
    'use strict';

    if($('.content_video').length > 0) {
        if(window.ad_type === 'sdo') {

            var lang = window.language.toUpperCase();
            switch(lang) {
                case 'FR':
                case 'EN':
                case 'DE':
                case 'ES':
                case 'IT':
                case 'PL':
                case 'PT':
                case 'RU':
                case 'TR':
                    break;
                default:
                    lang = 'EN';
                    break;
            }

            var data = {
                id: 'video-container',
                adData: {
                    sas_siteid: 172793,
                    sas_pagename: 'videos',
                    sas_target: window.sas_target,
                    sas_format_linears: '32764'
                },
                adRules: [{
                    duration_min: 0,
                    duration_max: -1,
                    data: {
                        prerolls: {
                            instances: 1
                        }
                        /*
                        midrolls: {
                            instances: 1,
                            percents: [50],
                            interval: 0
                        },
                        postrolls: {
                            instances: 1
                        },
                        overlays: {
                            instances: 1,
                            interval: 0,
                            percents: [20]
                        }
                        */
                    }
                }],
                callbacks: {
                    /*
                    adBegin: function(obj){
                        if (canLog){
                            console.log('An ad begun');
                            console.log('  * Controller ID: ' + obj.controllerID);
                            console.log('  * Break: ' + obj.state);
                            console.log('  * Break type: ' + obj.type);
                        }
                    },
                    adBreakBegin: function(state, controllerID){
                        if (canLog){
                            console.log('An ad break begun');
                            console.log('  * Break: ' + state);
                            console.log('  * Controller ID: ' + controllerID);
                        }
                    },
                    adBreakEnd: function(state, controllerID){
                        if (canLog){
                            console.log('An ad break ended');
                            console.log('  * Break: ' + state);
                            console.log('  * Controller ID: ' + controllerID);
                        }
                    },
                    adEnd: function(obj){
                        if (canLog){
                            console.log('An ad ended');
                            console.log('  * Controller ID: ' + obj.controllerID);
                            console.log('  * Break: ' + obj.state);
                            console.log('  * Break type: ' + obj.type);
                        }
                    },
                    contentEnd: function(obj){
                        if (canLog){
                            console.log('Video content ended');
                            console.log('  * Controller ID: ' + obj.controllerID);
                        }
                    },
                    videoEnd: function(obj){
                        if (canLog){
                            console.log('Video experience ended');
                            console.log('  * Controller ID: ' + obj.controllerID);
                        }
                    }
                    */
                },
                configuration: {
                    publisher: {
                        forceSkipDelay: true,
                        html5SeamlessFullscreen: true,
                        skipDelay: 3
                    }
                },
                contentData: window.sdo_video_info,
                contentPlayer: {
                    api: null
                },
                language: lang
            };

            if(typeof window.sas !== 'undefined' && typeof window.sas.video !== 'undefined') {
                window.videojs('content_video');
                window.sas.video.register(data);
            }

        } else {

            window.videojs('content_video');

            /* GOOGLE ADS INTEGRATION
            window.pause = function (id) {
                var player = window.videojs(id);
                player.ima.pauseAd();
            };

            var Player = function(id, adTagUrl) {
                this.id = id;
                this.init = function() {
                    var trigger_play = false;
                    var player = window.videojs(this.id).ready(function(){
                        var player = this;
                        player.on('ended', function() {
                            if(typeof window.next_video_url !== 'undefined') {
                                window.location.href = window.next_video_url;
                            }
                        });
                        player.on('play', function() {
                            if(trigger_play) {
                                window.ga('send', 'event', { eventCategory: 'Video', eventAction: $('#' + id).data('title'), eventLabel: 'Play'});
                            }
                        });
                    });

                    var options = {
                        id: id
                    };

                    // Add ad tag to video player
                    if(adTagUrl) {
                        options.adTagUrl = adTagUrl;
                    }

                    player.ima(options);

                    // Remove controls from the player on iPad to stop native controls from stealing
                    // our click
                    var contentPlayer = document.getElementById(id + '_html5_api');
                    if((navigator.userAgent.match(/iPad/i) ||
                        navigator.userAgent.match(/Android/i)) &&
                        contentPlayer.hasAttribute('controls')) {
                        contentPlayer.removeAttribute('controls');
                    }

                    // Initialize the ad container when the video player is clicked, but only the
                    // first time it's clicked.
                    var startEvent = 'click';
                    if(navigator.userAgent.match(/iPhone/i) ||
                        navigator.userAgent.match(/iPad/i) ||
                        navigator.userAgent.match(/Android/i)) {
                        startEvent = 'touchend';
                    }

                    if(typeof $('#'+this.id).attr('autoplay') !== 'undefined') {
                        try {
                            player.ima.initializeAdDisplayContainer();
                            player.ima.requestAds();
                        } catch (err) {}
                        player.play();
                    } else {
                        player.one(startEvent, function() {
                            try {
                                player.ima.initializeAdDisplayContainer();
                                player.ima.requestAds();
                            } catch (err) {}
                            player.play();
                            setTimeout(function() {
                                trigger_play = true;
                            }, 10);
                        });
                    }
                };
            };

            // Ad Tag Url is a link from DFP which contain all data required to display ads
            var adTagUrl = 'https://pubads.g.doubleclick.net/gampad/ads?sz=300x250|1920x1080|1x1|480x250&iu=/93804893/MC_Web_Video_1&impl=s&gdfp_req=1&env=vp&output=vast&unviewed_position_start=1&url=[referrer_url]&description_url=[description_url]&correlator=[timestamp]';

            var player = new Player('content_video', adTagUrl);

            player.init();
            */

        }
    }

}(jQuery));
