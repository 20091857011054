(function($) {
    'use strict';

    var form = $('.plan-your-route-form');
    var input_container = form.find('.plan-route-input-container');
    var overlay = $('.route-loading-overlay');
    var route_list_template = $($('.route-list-template').html());
    var route_list = $('.route-list');
    var route_result = $('.route-result');
    var turn_locations = $('.turn-locations');
    var no_route_error = $('.no-route-error');

    var renderRouteList = function(data) {
        route_result.show();
        route_list.html('');
        $.each(data.poi_list, function(key, value) {
            var template = route_list_template.clone();
            template.find('.time').html(value.time);
            template.find('.location').html(value.name);
            template.find('.weather-icon').html(value.info.icon);
            template.find('.temp-text').html(value.info.temp);
            template.find('.prec-text').html(value.info.prec);
            template.find('.wind-text').html(value.info.wind);
            template.find('.wind-icon').css('transform', 'rotate(' + value.info.wind_dir + 'rad)');
            route_list.append(template);
        });
        route_result.find('.departure-time').html(data.departure);
        route_result.find('.travel-time').html(data.travel_time);
        route_result.find('.travel-distance').html(data.travel_length);
    };

    form.on('change', '.js-search-location', function() {
        $(this).removeAttr('data-lat').removeAttr('data-lon');
    });

    var start_date = $('[name="start_date"]');
    var start_time = $('[name="start_time"]');
    var depart_select_info = $('.depart-select-info');

    var update_depart_info_and_set_depart = function() {
        $('input[name="depart_type"][value="depart"]').prop('checked', true);
        update_depart_info();
    };

    var update_depart_info = function() {
        var depart_type = $('input[name="depart_type"]:checked').val();
        if(depart_type === 'depart') {
            depart_select_info.html(start_date.val() + ' ' + start_time.val());
        } else {
            depart_select_info.html(depart_select_info.data('now'));
        }
    };

    start_date.on('change', update_depart_info_and_set_depart);
    start_time.on('change', update_depart_info_and_set_depart);

    $('input[name="depart_type"]').on('change', function() {
        update_depart_info();
    });

    turn_locations.on('click', function() {
        var inputs = input_container.find('.js-search-location');
        var data = [];
        inputs.each(function() {
            data.push({
                text: $(this).val(),
                lat: $(this).data('lat'),
                lon: $(this).data('lon')
            });
        });
        data.reverse();
        inputs.each(function(key) {
            $(this).val(data[key].text);
            var lat = data[key].lat;
            var lon = data[key].lon;
            if(typeof lat !== 'undefined') {
                $(this).attr('data-lat', lat).attr('data-lon', lon);
                $(this).data('lat', lat).data('lon', lon);
            } else {
                $(this).removeAttr('data-lat').removeAttr('data-lon');
                $(this).removeData('lat').removeData('lon');
            }
        });
        updateLocations();
    });

    var planRoute = function() {
        var data_array = form.serializeArray();
        var data = {};
        $.each(data_array, function(key, value) {
            data[value.name] = value.value;
        });
        if(data.locations === '') {
            return false;
        }

        $.each(data.locations.split(','), function(key, value) {
            if(key === 0) {
                window.ga('send', 'event', { eventCategory: 'Route planner', eventAction: 'Departure', eventLabel: value});
            } else {
                window.ga('send', 'event', { eventCategory: 'Route planner', eventAction: 'Destination', eventLabel: value});
            }
        });

        switch(data.depart_type) {
            case 'now':
                window.ga('send', 'event', { eventCategory: 'Route planner', eventAction: 'Time', eventLabel: 'Now'});
                break;
            case 'depart':
                window.ga('send', 'event', { eventCategory: 'Route planner', eventAction: 'Time', eventLabel: 'Later'});
                break;
        }

        switch(data.mode) {
            case 'car':
                window.ga('send', 'event', { eventCategory: 'Route planner', eventAction: 'Mode', eventLabel: 'Car'});
                break;
            case 'pedestrian':
                window.ga('send', 'event', { eventCategory: 'Route planner', eventAction: 'Mode', eventLabel: 'Walk'});
                break;
            case 'bike':
                data.mode = 'car';
                window.ga('send', 'event', { eventCategory: 'Route planner', eventAction: 'Mode', eventLabel: 'Motorbike'});
                break;
            case 'bicycle':
                window.ga('send', 'event', { eventCategory: 'Route planner', eventAction: 'Mode', eventLabel: 'Bicycle'});
                break;
        }

        if(typeof data.avoid_highway !== 'undefined') {
            window.ga('send', 'event', { eventCategory: 'Route planner', eventAction: 'Options', eventLabel: 'Avoid highways'});
        }
        if(typeof data.avoid_ferries !== 'undefined') {
            window.ga('send', 'event', { eventCategory: 'Route planner', eventAction: 'Options', eventLabel: 'Avoid ferries'});
        }
        if(typeof data.avoid_tolls !== 'undefined') {
            window.ga('send', 'event', { eventCategory: 'Route planner', eventAction: 'Options', eventLabel: 'Avoid tolls'});
        }

        window.ga('send', 'event', { eventCategory: 'Route planner', eventAction: 'Plan your route', eventLabel: 'Submit'});

        no_route_error.hide();
        overlay.show();
        $.ajax({
            method: 'POST',
            url: form.attr('action'),
            data: data
        }).done(function(response) {
            overlay.hide();
            if(response.success) {
                $('.morecast-map').trigger('update-route-data', response.data);
                renderRouteList(response.data);
            } else {
                no_route_error.show();
            }
        });
    };

    form.on('submit', function() {
        planRoute();
        return false;
    });

    var updateLocations = function() {
        var locations = [];
        input_container.find('.js-search-location').each(function() {
            var lat = $(this).data('lat');
            var lon = $(this).data('lon');
            if(typeof lat !== 'undefined') {
                locations.push(lon + ' ' + lat);
            }
        });
        if(input_container.find('.js-search-location').length > 2) {
            form.find('.location-search-container').addClass('can-remove');
        } else {
            form.find('.location-search-container').removeClass('can-remove');
        }
        var location_data = '';
        if(locations.length > 1) {
            location_data = locations.join(',');
        }
        input_container.find('[name="locations"]').val(location_data);
    };

    input_container.on('click', '.search-result a', function() {
        var input = $(this).closest('.search-container').find('.js-search-location');
        input.attr('data-lat', $(this).data('lat'));
        input.attr('data-lon', $(this).data('lon'));
        input.data('lat', $(this).data('lat'));
        input.data('lon', $(this).data('lon'));
        input.val($(this).html());
        $(this).closest('.search-result').html('').hide();
        updateLocations();
        return false;
    });

    input_container.on('click', '.search-container-remove', function() {
        $(this).closest('.search-container').remove();
        updateLocations();
        return false;
    });

    updateLocations();

    var search_container_template = $('.search-container-template');

    $('.add-location-link').on('click', function() {
        var template = search_container_template.clone().removeClass('search-container-template').show();
        input_container.append(template);
        template.find('.js-search-location').searchLocation();
        updateLocations();
        return false;
    });

    var route_container = $('.plan-your-route-container');
    if(route_container.length > 0) {
        $(document).on('keydown', function(event) {
            var code = event.keyCode || event.which;
            if(code === 13) {
                setTimeout(function() {
                    planRoute();
                }, 100);
                //return false;
            }
        });
    }


}(jQuery));
