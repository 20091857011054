(function($) {
    'use strict';

    var container,
        unit = {};

    var defaultGraph = {
        id: "default",
        title: "",
        type: "line",
        values: [],
        openEnd: false,
        marker: {
            show: true,

            points: {
                show: true,
                size: [10],
                type: "circle",
                image: [""],
                rotation: ['0deg']
            },
            labels: {
                show: false,
                offset: {
                    x: 0,
                    y: 15
                }
            }
        },
        offset: {
            x: 0,
            y: 0
        }
    };

    var defaultChart = {
        graphs: [
        ],
        areas:[],
        grid: {
            fixedHeight: false,
            units: {
                width: 70,
                height: 80,
                x: {
                    stepsPerUnit: 1
                },
                y: {
                    min: {
                        value: 0, // int or 'dynamic'
                        padding: 0
                    },
                    max: {
                        value: 'dynamic',
                        padding: 0
                    },
                    stepsTotal: 'dynamic',
                    stepsPerUnit: 1
                }
            },
            offset: {
                x: 0,
                y: 0
            }
        },
        axis: {
            show: true,
            x: {
                show: false,
                container: false,
                size: 50,
                labels: {
                    prefix: "",
                    suffix: "",
                    show: true,
                    anchor: 'middle',
                    offset: {
                        x: 0,
                        y: -20
                    }
                }
            },
            y: {
                show: true,
                container: false,
                size: 50,
                labels: {
                    prefix: "",
                    suffix: "",
                    show: true,
                    anchor: 'middle',
                    offset: {
                        x: 0,
                        y: -20
                    }
                }
            }
        },
        treshold: {
            value: 13,
            classes: {
                below: "psc-below-treshold",
                above: "psc-above-treshold"
            }
        },
        crop: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0
        }
    };


    if (typeof $ !== 'undefined') {
        $.fn.pandoChart = function (data) {
            var masterGroup,
                chartData = $.extend(true, {}, defaultChart, data);

            $.each(data.graphs, function(i,graph){
                var tempGraph = $.extend(true, {}, defaultGraph, graph);
                chartData.graphs[i] = tempGraph;
            });

            container = $(this);

            var xTotal = 0;

            $.each(chartData.graphs, function(i, graph){

                if(graph.openEnd){
                    var first = graph.values[0];
                    var last = graph.values[graph.values.length - 1];

                    graph.values.unshift(first);
                    graph.values.push(last);
                }

                if(graph.values.length > 0){
                    xTotal = Math.max(graph.values.length-((graph.openEnd)?2:0), xTotal);
                }
            });


//Units

            unit.padding = {
                min: chartData.grid.units.y.min.padding,
                max: chartData.grid.units.y.max.padding
            };

            var yMin = chartData.grid.units.y.min.value,
                yMax = chartData.grid.units.y.max.value;

            unit.range = {};

            var tempMin, actMin;
            if (yMin === 'dynamic') {
                $.each(chartData.graphs, function (graphId, graph) {
                    if(graphId === 0){
                        actMin = getExtremes(graph.values).min;
                    }
                    tempMin = Math.min(actMin, getExtremes(graph.values).min);
                });
            } else {
                tempMin = yMin;
            }

            unit.range.min = tempMin;

            var tempMax, actMax;
            if (yMax === 'dynamic') {
                $.each(chartData.graphs, function (graphId, graph) {
                    unit.range.max = getExtremes(graph.values).max;

                    if(graphId === 0){
                        actMax = getExtremes(graph.values).max;
                    }
                    tempMax = Math.max(actMax, getExtremes(graph.values).max);
                });
            } else {
                tempMax = yMax;
            }

            unit.range.max = tempMax;

            unit.stepsTotal = {
                x: xTotal,
                y: unit.range.max - unit.range.min
            };

            unit.stepsPerUnit = {x: Math.max(chartData.grid.units.x.stepsPerUnit, 1)};
            unit.dimension = {x: chartData.grid.units.width};

            if(!chartData.grid.fixedHeight){
                unit.stepsPerUnit.y = Math.max(chartData.grid.units.y.stepsPerUnit, 1);
                unit.dimension.y = chartData.grid.units.height- (unit.padding.min+unit.padding.max);
            } else {
                if(chartData.grid.units.y.stepsTotal !== 'dynamic'){
                    unit.stepsTotal.y = chartData.grid.units.y.stepsTotal;
                    unit.stepsPerUnit.y = Math.ceil(Math.max((unit.range.max - unit.range.min),1)/unit.stepsTotal.y);
                } else {
                    unit.stepsPerUnit.y = 1;
                }
                unit.dimension.y = (chartData.grid.fixedHeight - (unit.padding.min+unit.padding.max)) / unit.stepsTotal.y;
            }


            /* */
            container.css('height', getGraphDimension(chartData).y);

            var svg = SVG(container.get(0)).size(getGraphDimension(chartData).x, getGraphDimension(chartData).y);
            masterGroup = svg.group().addClass('psc-master-group');

            container.pscDrawGrid(chartData, masterGroup);

            if (chartData.areas.length !== 0) {
                container.pscDrawArea(chartData,masterGroup);
            }

            container.pscDrawGraph(chartData,masterGroup);

            if(chartData.axis.show) {
                container.pscDrawAxis(chartData,masterGroup);
            }
            masterGroup.move(-chartData.crop.left, -chartData.crop.top);
        };


// DRAWING


        $.fn.pscDrawGrid = function (chartData,masterGroup) {
            var rect, gridbars,
                offsetX = chartData.grid.offset.x,
                offsetY = chartData.grid.offset.y,
                firstLine = Math.floor(unit.range.min),
                lastLine = Math.ceil(unit.range.max) + 1 + unit.stepsPerUnit.y  * (((unit.padding.max+unit.padding.min)>=unit.dimension.y) ? Math.ceil((unit.padding.max+unit.padding.min)/unit.dimension.y) : 0),
                gridStepsX = unit.stepsTotal.x + ((offsetX !== 0) ? 1 : 0);

            gridbars = masterGroup.group().addClass('grid-bg');

            if(chartData.grid.units.y.stepsTotal !== 'dynamic'){
                lastLine = firstLine + chartData.grid.units.y.stepsTotal*unit.stepsPerUnit.y + unit.stepsPerUnit.y;
            }

                var classCol = 0;
                for (var u = 0; u <= gridStepsX; u++) {
                    if (u % unit.stepsPerUnit.x === 0) {
                        var classRow = 0;
                        for (var v = firstLine; v <= lastLine; v++) {
                            if (v % unit.stepsPerUnit.y === 0) {
                                rect = gridbars.rect(unit.dimension.x, unit.dimension.y);
                                rect.move(svgCoord(chartData,u).x, svgCoord(chartData,v).y);
                                rect.addClass('psc-grid-bg psc-grid-bg-col-' + classCol + ' psc-grid-bg-row-' + (classRow));
                                classRow++;
                            }
                        }
                        classCol++;
                    }
                }

                var grid = masterGroup.group().addClass('grid');
                for (var i = 0; i <= gridStepsX; i++) {
                    if (i % unit.stepsPerUnit.x === 0) {
                        grid.line(svgCoord(chartData,i).x, 0, svgCoord(chartData,i).x, getGraphDimension(chartData).y + (((offsetY !== 0) ? 1 : 0) * unit.dimension.y)).addClass('psc-grid-line psc-grid-line-v');
                    }
                }

                for (var j = firstLine; j <= lastLine; j++) {
                    if (j % unit.stepsPerUnit.y === 0) {
                        grid.line(0, svgCoord(chartData,j).y, getGraphDimension(chartData).x + (((offsetX !== 0) ? 1 : 0) * unit.dimension.x), svgCoord(chartData,j).y).addClass('psc-grid-line psc-grid-line-h line-' + j);
                    }
                }

            gridbars.move(offsetX, -chartData.grid.offset.y);

        };


        $.fn.pscDrawAxis = function(chartData,masterGroup){
            container = $(this);
            /*
                TODO: X-Axis Lines & Labels & Container
             */

            var zero = {};
            zero.x = (chartData.axis.y.show)?chartData.axis.y.size:0;
            zero.y = (chartData.axis.x.show)?chartData.axis.x.size:0;


            if (chartData.axis.x.show) {
                var axisXGroup = masterGroup.group().addClass('psc-axis-group-x');
                var axisXFill = axisXGroup.rect(container.width(), chartData.axis.y.size);
                axisXFill.move(0, container.height() - chartData.axis.x.size);
                axisXFill.addClass('psc-axis-fill');

                var axisXLine = axisXGroup.line(zero.x, container.height() - zero.y, container.width(), container.height() - zero.y);
                axisXLine.addClass('psc-axis-line');
            }

            if  (chartData.axis.y.show) {
                var axisYsvg, axisYGroup;

                if(chartData.axis.y.container){
                    axisYsvg = SVG(chartData.axis.y.container).size(chartData.axis.y.size, getGraphDimension(chartData).y);
                } else {
                    axisYsvg = masterGroup;
                }
                axisYGroup = axisYsvg.group().addClass('psc-axis-group-y');

                var axisYFill = axisYGroup.rect(chartData.axis.y.size,container.height());
                axisYFill.move(0, 0);
                axisYFill.addClass('psc-axis-fill');

                var axisYLine = axisYGroup.line(zero.x, 0, zero.x, container.height() - zero.y);
                axisYLine.addClass('psc-axis-line');

                var showAxisLabels = chartData.axis.y.labels.show;

                if(showAxisLabels) {
                    var firstLine = Math.floor(unit.range.min),
                        lastLine = Math.ceil(unit.range.max) + 1 + unit.stepsPerUnit.y  * (((unit.padding.max+unit.padding.min)>=unit.dimension.y) ? Math.ceil((unit.padding.max+unit.padding.min)/unit.dimension.y) : 0);


                    if(chartData.grid.units.y.stepsTotal !== 'dynamic'){
                        lastLine = firstLine + chartData.grid.units.y.stepsTotal * unit.stepsPerUnit.y + unit.stepsPerUnit.y;
                    }

                    var grid = axisYGroup.group().addClass('psc-axis-label-lines');

                    for (var j = firstLine; j <= lastLine; j++) {
                        if (j % unit.stepsPerUnit.y === 0) {
                            grid.line(0, svgCoord(chartData,j).y, chartData.axis.y.size, svgCoord(chartData,j).y).addClass('psc-axis-label-line psc-axis-label-line-' + j);

                            var label = axisYGroup.plain(chartData.axis.y.labels.prefix +j+ chartData.axis.y.labels.suffix).addClass('psc-axis-label-text');
                            label.font({
                                anchor: chartData.axis.y.labels.anchor
                            });

                            var textOffsetX = chartData.axis.y.labels.offset.x;
                            var textOffsetY = chartData.axis.y.labels.offset.y;

                            var offsetY = svgCoord(chartData,j).y + textOffsetY;
                            label.move(chartData.axis.y.size/2 + textOffsetX, offsetY);
                            if(offsetY < 0) {
                                label.remove();
                            }
                        }
                    }

                    grid.move(0, chartData.grid.offset.y);
                }
            }
        };

        $.fn.pscDrawGraph = function (chartData,masterGroup) {
            var container = $(this);
            $.each(chartData.graphs, function (graphID, graph) {
                var graphType = graph.type,
                    pathGroup = masterGroup.group().addClass('psc-path psc-path-' + graphID + ' psc-path-' + graph.type + ((graph.customClasses) ? ' ' + graph.customClasses : '')).attr('id', graph.id),
                    markerGroup = masterGroup.group().addClass('psc-marker psc-marker-' + graph.id).attr('id', graph.id + '-marker');

                switch (graphType) {
                    case 'bar':
                        $.each(graph.values, function (x, y) {
                            var rect = pathGroup.rect(unit.dimension.x, y * unit.dimension.y / unit.stepsPerUnit.y);
                            rect.move((x * unit.dimension.x)-(unit.dimension.x/2), svgCoord(chartData,y).y);
                            rect.addClass('psc-bar psc-bar-' + x);

                            var line = pathGroup.line(0, svgCoord(chartData,y).y, unit.dimension.x, svgCoord(chartData,y).y);
                            line.move((x * unit.dimension.x)-(unit.dimension.x/2), svgCoord(chartData,y).y);
                            line.addClass('psc-bar-line psc-bar-line-' + x);

                            if(y < chartData.treshold.value) {
                                rect.addClass(chartData.treshold.classes.below);
                                line.addClass(chartData.treshold.classes.below);
                            } else {
                                rect.addClass(chartData.treshold.classes.above);
                                line.addClass(chartData.treshold.classes.above);
                            }
                        });
                        break;
                    default:
                        var pathData = getPathData(chartData,graph.values, graphType);
                        pathGroup.path(pathData.d);
                }

                pathGroup.move(getOffset(chartData, graph).x, getOffset(chartData, graph).y);

                if (graph.marker.show) {
                    container.pscDrawMarker(chartData, graph, markerGroup);
                }
            });
        };

        $.fn.pscDrawMarker = function(chartData, graph, markerGroup){
            var showLabels = graph.marker.labels.show,
                showPoints = graph.marker.points.show;

            if (showLabels) {
                var textGroup, textLabel, label, position = {x:'',y:''};

                position.x = graph.marker.labels.offset.x;
                position.y = graph.marker.labels.offset.y * -1;
                textGroup = markerGroup.group().addClass('psc-labels');

                $.each(graph.values, function (x, y) {
                    var drawLabel = true;
                    if(graph.openEnd){
                        drawLabel = (x!==0 && x!==graph.values.length-1);
                    }

                    if(drawLabel){
                        textLabel = textGroup.group().addClass('psc-label psc-label-' + x);
                        label = textLabel.plain(y).font({
                            anchor: 'middle'
                        });
                        textLabel.move(svgCoord(chartData,x).x + position.x, svgCoord(chartData,y).y + position.y);
                    }
                });
            }


            if (showPoints) {
                var pointGroup;
                pointGroup = markerGroup.group().addClass('psc-points');

                $.each(graph.values, function (x, y) {
                    var point,
                        pointType = graph.marker.points.type,
                        pointSize = graph.marker.points.size[x % graph.marker.points.size.length],
                        imagePath = graph.marker.points.image[x % graph.marker.points.image.length],
                        rotation = graph.marker.points.rotation[x % graph.marker.points.rotation.length];


                    switch (pointType) {
                        case 'circle':
                            point = pointGroup.circle(pointSize).addClass('psc-point psc-point-circle psc-point-' + x);
                            break;
                        case 'square':
                            point = pointGroup.rect(pointSize, pointSize).addClass('psc-point psc-point-square psc-point-' + x);
                            break;
                        case 'image':
                            if(imagePath){
                                point = pointGroup.image(imagePath, pointSize, pointSize).addClass('psc-point psc-point-image psc-point-' + x);
                            } else {
                                point.addClass('psc-point-img-path-missing');
                            }
                            break;
                    }

                    point.move(svgCoord(chartData,x).x - (pointSize / 2), svgCoord(chartData,y).y - (pointSize / 2));

                    if(y <= chartData.treshold.value) {
                        point.addClass(chartData.treshold.classes.below);
                    } else {
                        point.addClass(chartData.treshold.classes.above);
                    }

                    if(rotation) {
                        //point.style('transform', 'rotate(' + rotation + ','+pointSize/2+'px,'+ pointSize/2+'px)');
                        point.style('transform-origin', svgCoord(chartData,x).x +'px '+ svgCoord(chartData,y).y+'px');
                        point.style('transform', 'rotate(' + rotation + ')');
                    }
                });
            }

            markerGroup.move(getOffset(chartData,graph).x, getOffset(chartData,graph).y);
        };
    }

    $.fn.pscDrawArea = function(chartData,masterGroup){
        $.each(chartData.areas, function(areaID, area) {
            var path, values = [], areaPath,
                areaGroup = masterGroup.group().addClass('psc-area psc-area-' + areaID),
                graphs = area.graphs;
                areaGroup.attr('id', area.id);



            $.each(graphs, function (graphId, graph) {
                var graphData = chartData.graphs.filter(function (obj) {
                    return obj.id === graph;
                });

                var tempValues = [];

                if (graphData.length > 0) {

                    $.each(graphData[0].values, function (i, v) {
                        tempValues.push(v);
                    });

                    tempValues = getPathData(chartData,tempValues, graphData[0].type, (graphId % 2 !== 0), (graphId === 0) ? 'M' : 'L');
                }

                values += tempValues.d;

                if (graphs.length === 1) {
                    values += 'L ' + tempValues.cursor.x + ' ' + svgCoord(chartData,0).y + ' ';
                    values += 'L ' + svgCoord(chartData,0).x + ' ' + svgCoord(chartData,0).y;
                }

            });

            path = values + ' Z';

            areaPath = areaGroup.path(path);
            areaPath.addClass('psc-area psc-area-' + areaID);

            if(typeof area.offset === 'undefined'){
                area.offset = {x:0, y:0};
            } else {
                if(typeof area.offset.x === 'undefined') {
                    area.offset.x = 0;
                }
                if(typeof area.offset.y === 'undefined') {
                    area.offset.y = 0;
                }
            }

            areaGroup.move(getOffset(chartData, area).x, getOffset(chartData,area).y);

        });

    };



// HELPER

    function getPathData(chartData, coordsArray, graphType, drawReverse, startWith){
        var type = graphType || 'line',
            d,lastX, lastY,
            point = 1;
            coordsArray = coordsArray || [];

        drawReverse = drawReverse || false;
        startWith = startWith || 'M';

        switch(type){
            case 'curved':
                var coords=[],
                    curvePoints;

                if(drawReverse){
                    coordsArray.reverse();
                }

                $.each(coordsArray, function(x,y){
                    if(drawReverse){
                        x = (coordsArray.length-1)-x;
                    }

                    coords.push(x);
                    coords.push(y);
                });

                curvePoints = getCurvePoints(coords,0.5,unit.dimension.x);

                $.each(curvePoints, function(step, value){
                    if(step%2===0){
                        if (point === 1){
                            d = startWith+' ';
                        } else {
                            d += ' L';
                        }
                        d += ' ' + (svgCoord(chartData,value).x);
                        lastX = svgCoord(chartData,value).x;
                    } else {
                        d += ' ' + svgCoord(chartData,value).y;
                        lastY = svgCoord(chartData,value).y;
                    }
                    point++;
                });
                break;
            default:
                $.each(coordsArray, function(x, y) {
                    point = svgCoord(chartData,x).x+' '+svgCoord(chartData,y).y;
                    if(x===0){
                        d = point;
                    } else{
                        if(drawReverse){
                            d = point + ' L '+d;
                        } else{
                            d = d + ' L ' + point;
                        }
                    }
                    lastX = svgCoord(chartData,x).x;
                    lastY = svgCoord(chartData,y).y;
                });

                d = startWith+' '+d;
        }
        return {d: d, cursor: {x: lastX, y: lastY}};
    }

    function svgCoord(chartData,value){
        return {
            x:  value*(unit.dimension.x/unit.stepsPerUnit.x),
            y:  getGraphDimension(chartData).y-((value*(unit.dimension.y/unit.stepsPerUnit.y)))+(unit.range.min*(unit.dimension.y/unit.stepsPerUnit.y))-unit.padding.min
        };
    }

    function getOffset(chartData, el) {
        var o = el.offset,
            oX = o.x,
            oY = o.y;

        if (chartData.axis.show) {
            if (chartData.axis.x.show) {
                var axisSizeX = chartData.axis.x.size;
                oY -= axisSizeX;
            }

            if (chartData.axis.y.show) {
                var axisSizeY = chartData.axis.y.size;
                oX += axisSizeY;
            }
        }

        return {x: oX, y: oY};
    }

    function getExtremes(array){
        if(array.length > 0) {
            var min, max;
            $.each(array, function (i, val) {
                min = Math.min(((i === 0) ? val : min), val);
                max = Math.max(((i === 0) ? val : max), val);
            });
            return {min: min, max: max};
        }
        return false;
    }

    function getGraphDimension(chartData){
        var graphWidth, graphHeight,
            containerWidth = container.width();

        if(!chartData.grid.fixedHeight){
            graphHeight = unit.stepsTotal.y * (unit.dimension.y / unit.stepsPerUnit.y) + Math.floor(unit.dimension.y / 2)  - (chartData.crop.top + chartData.crop.bottom);
        } else {
            graphHeight = chartData.grid.fixedHeight;
        }

        graphWidth = Math.max(unit.stepsTotal.x * unit.dimension.x + Math.floor(unit.dimension.x / 2), containerWidth) - (chartData.crop.left + chartData.crop.right);

        return {x:graphWidth, y:graphHeight};
    }

    function getCurvePoints(pts, tension, numOfSegments, isClosed) {

        // use input value if provided, or use a default value
        tension = (typeof tension !== 'undefined') ? tension : 0.5;
        isClosed = isClosed ? isClosed : false;
        numOfSegments = numOfSegments ? numOfSegments : 16;

        var _pts = [], res = [],    // clone array
            x, y,           // our x,y coords
            t1x, t2x, t1y, t2y, // tension vectors
            c1, c2, c3, c4,     // cardinal points
            st, t, i;       // steps based on num. of segments

        // clone array so we don't change the original
        //
        _pts = pts.slice(0);

        // The algorithm require a previous and next point to the actual point array.
        // Check if we will draw closed or open curve.
        // If closed, copy end points to beginning and first points to end
        // If open, duplicate first points to befinning, end points to end
        if (isClosed) {
            _pts.unshift(pts[pts.length - 1]);
            _pts.unshift(pts[pts.length - 2]);
            _pts.unshift(pts[pts.length - 1]);
            _pts.unshift(pts[pts.length - 2]);
            _pts.push(pts[0]);
            _pts.push(pts[1]);
        }
        else {
            _pts.unshift(pts[1]);   //copy 1. point and insert at beginning
            _pts.unshift(pts[0]);
            _pts.push(pts[pts.length - 2]); //copy last point and append
            _pts.push(pts[pts.length - 1]);
        }


        // ok, lets start..

        // 1. loop goes through point array
        // 2. loop goes through each segment between the 2 pts + 1e point before and after
        for (i=2; i < (_pts.length - 4); i+=2) {
            for (t=0; t <= numOfSegments; t++) {

                // calc tension vectors
                t1x = (_pts[i+2] - _pts[i-2]) * tension;
                t2x = (_pts[i+4] - _pts[i]) * tension;

                t1y = (_pts[i+3] - _pts[i-1]) * tension;
                t2y = (_pts[i+5] - _pts[i+1]) * tension;

                // calc step
                st = t / numOfSegments;

                // calc cardinals
                c1 =   2 * Math.pow(st, 3)  - 3 * Math.pow(st, 2) + 1;
                c2 = -(2 * Math.pow(st, 3)) + 3 * Math.pow(st, 2);
                c3 =       Math.pow(st, 3)  - 2 * Math.pow(st, 2) + st;
                c4 =       Math.pow(st, 3)  -     Math.pow(st, 2);

                // calc x and y cords with common control vectors
                x = c1 * _pts[i]    + c2 * _pts[i+2] + c3 * t1x + c4 * t2x;
                y = c1 * _pts[i+1]  + c2 * _pts[i+3] + c3 * t1y + c4 * t2y;

                //store points in array
                res.push(x);
                res.push(y);

            }
        }
        return res;
    }
}(jQuery));
