(function($) {
    'use strict';

    var openFullscreen = function(element) {
        element.closest('.fullscreen-holder-container').addClass('is-fullscreen');
        $(window).trigger('resize');
    };

    var closeFullscreen = function(element) {
        element.closest('.fullscreen-holder-container').removeClass('is-fullscreen');
        $(window).trigger('resize');
    };

    $('.fullscreen-open').on('click', function() {
        openFullscreen($(this));
    });

    $('.fullscreen-close').on('click', function() {
        closeFullscreen($(this));
    });

    var parseUrl = function (url, layer, moment_date) {
        url = url.replace('{layer}', layer);
        return url.replace('{time}', moment_date.format('YYYY-MM-DD[T]HH:mm:ss[Z]'));
    };

    $('.morecast-map').each(function() {
        var map_container = $(this);
        var data = $(this).data('map-data');
        var container = $(this).closest('.morecast-map-container');
        var coordinates = [data.lon, data.lat];

        var radar_layer = null;
        var animation_running = false;
        var updateInterval;
        var startAnimation;
        var map_layers = [];

        map_container.on('click', function() {
            if($(window).width() < 768) {
                openFullscreen(map_container);
            }
        });

        var addRadarLayer = function(container, map, data) {
            container.find('.change-map-overlay').removeClass('active');
            container.find('.change-map-overlay[data-type="' + data.type + '"]').addClass('active');

            if(radar_layer !== null) {
                if(animation_running) {
                    toggleTimelineAnimation(false);
                }
                map.removeLayer(radar_layer);
                $.each(map_layers, function(key, value) {
                    map.removeLayer(value);
                });
            }

            var map_timeline = container.find('.map-timeline');
            container.removeClass('animation-is-forecast');
            map_timeline.find('.timeline-time.start').text(data.start_time);
            map_timeline.find('.timeline-time.end').text(data.end_time);

            var timeline_line_container = map_timeline.find('.timeline-line-container');
            timeline_line_container.find('.handle').css('left', ((data.measured_frames - 1)/(data.total_frames - 1) * 100) + '%');
            timeline_line_container.find('.bar-forecast').css('left', ((data.measured_frames - 1)/(data.total_frames - 1) * 100) + '%');
            timeline_line_container.find('.handle .handle-tooltip').text(data.current_time);

            timeline_line_container.find('.spot').remove();

            for(var a = 1; a < data.total_frames; a++) {
                var div = $('<div/>', {
                    'class': 'spot',
                    style: 'width: ' + (100/(data.total_frames - 1)) + '%;'
                });
                for(var b = 0; b < 3; b++) {
                    div.append('<div class="sub-spot"></div>');
                }
                timeline_line_container.append(div);
            }

            var animation_data = data.data;
            var deliveryDelaySeconds = 1000 * 60 * animation_data.measured.delivery_delay;
            var alignmentSeconds = 1000 * 60 * animation_data.measured.alignment;
            var moment_date = moment.utc(Math.floor((new Date().getTime() - deliveryDelaySeconds) / alignmentSeconds) * alignmentSeconds);
            var wmts_source = new ol.source.XYZ({ url: parseUrl(animation_data.measured.url, animation_data.measured.layer, moment_date) });

            radar_layer = new ol.layer.Tile({ opacity: 0.7, source: wmts_source });
            map.addLayer(radar_layer);

            moment_date.subtract(animation_data.measured.resolution * (animation_data.measured.frames - 1), 'minutes');

            var time = animation_data.measured.frames - 1;
            var player_frames = [];
            for(var i = 0; i < animation_data.measured.frames; i++) {
                player_frames.push({
                    url: animation_data.measured.url,
                    layer: animation_data.measured.layer,
                    moment: moment_date.clone()
                });
                moment_date.add(animation_data.measured.resolution, 'minutes');
            }

            if(typeof animation_data.forecast !== 'undefined') {
                for(var j = 0; j < animation_data.forecast.frames; j++) {
                    player_frames.push({
                        url: animation_data.forecast.url,
                        layer: animation_data.forecast.layer,
                        moment: moment_date.clone()
                    });
                    moment_date.add(animation_data.forecast.resolution, 'minutes');
                }
            }

            var loading_overlay = container.find('.map-timeline-loading-overlay');
            var progress_bar = loading_overlay.find('.progress-bar');
            var displayLoadingOverlay = function(percent) {
                loading_overlay.show();
                progress_bar.css('width', percent + '%');
                if(percent >= 100) {
                    loading_overlay.hide();
                }
            };

            var timeline_handle = container.find('.timeline-line-container .handle');
            var update_timeline_handle = function(frame) {
                var offset_percent = 0;
                if(frame > 0) {
                    offset_percent = frame / (data.total_frames - 1) * 100;
                }
                if(frame === 0) {
                    timeline_handle.addClass('remove-animation');
                }
                timeline_handle.css('left', offset_percent + '%');
                timeline_handle.find('.handle-tooltip').text(data.time_frames[frame]);
                if(frame === 0) {
                    setTimeout(function() {
                        timeline_handle.removeClass('remove-animation');
                    }, 10);
                }
            };

            var startInterval = function() {
                var updateLayers = function() {
                    radar_layer.setOpacity(0);
                    $.each(map_layers, function(key, value) {
                        value.setOpacity(0);
                    });
                    time++;
                    if(typeof map_layers[time] === 'undefined') {
                        time = 0;
                    }
                    if(time >= data.measured_frames) {
                        container.addClass('animation-is-forecast');
                    } else {
                        container.removeClass('animation-is-forecast');
                    }
                    map_layers[time].setOpacity(0.7);
                    update_timeline_handle(time);
                };
                updateLayers();
                updateInterval = setInterval(updateLayers, 1200);
            };

            var tiles_added = false;
            startAnimation = function() {
                if(!tiles_added) {
                    var progress = {
                        loading: 0,
                        loaded: 0,
                        interval_started: false,
                        last_percent_loaded: false,
                        addLoading: function() {
                            ++this.loading;
                            this.update();
                        },
                        addLoaded: function() {
                            setTimeout(function() {
                                ++this.loaded;
                                this.update();
                            }.bind(this), 100);
                        },
                        update: function() {
                            var percent_loaded = Math.round(this.loaded / this.loading * 100);
                            if(percent_loaded === 100 && !this.interval_started) {
                                startInterval();
                                this.interval_started = true;
                            }
                            if(percent_loaded > this.last_percent_loaded) {
                                displayLoadingOverlay(percent_loaded);
                            }
                            this.last_percent_loaded = percent_loaded;
                        }
                    };
                    map_layers = [];
                    $.each(player_frames, function(key, value) {
                        var source = new ol.source.XYZ({ url: parseUrl(value.url, value.layer, value.moment) });
                        var layer = new ol.layer.Tile({
                            opacity: 0,
                            source: source
                        });
                        source.on('tileloadstart', function() {
                            progress.addLoading();
                        });
                        source.on('tileloadend', function() {
                            progress.addLoaded();
                        });
                        source.on('tileloaderror', function() {
                            progress.addLoaded();
                        });
                        map.addLayer(layer);
                        map_layers.push(layer);
                    });
                    tiles_added = true;
                } else {
                    startInterval();
                }
            };
        };

        var min_zoom = parseInt(data.min_zoom);
        var max_zoom = parseInt(data.max_zoom);

        var map = new ol.Map({
            layers: [
                new ol.layer.Tile({
                    opacity: 0.65,
                    source: new ol.source.XYZ({
                        url: 'https://{1-4}.base.maps.cit.api.here.com/maptile/2.1/maptile/newest/normal.day/{z}/{x}/{y}/256/png8?app_id=' + window.config.nokia_here.app_id + '&app_code=' + window.config.nokia_here.app_code,
                        attributions: [
                            new ol.Attribution({
                                html: "&copy; <a href='https://legal.here.com/en-gb/terms' target='_blank'><img src='/assets/img/by_here.png' border='0'></a>",
                            })
                        ]
                    })
                })
            ],
            target: this,
            controls: ol.control.defaults({
                attributionOptions: ({
                    collapsible: false
                }),
                zoom: true,
                FullScreen: true
            }),
            interactions: ol.interaction.defaults({
                mouseWheelZoom: false,
                dragPan: data.allow_drag,
                altShiftDragRotate: false,
                pinchRotate: false
            }),
            view: new ol.View({
                center: ol.proj.transform(coordinates, 'EPSG:4326', 'EPSG:3857'),
                zoom: data.current_zoom,
                minZoom: min_zoom,
                maxZoom: max_zoom
            })
        });

        var last_zoom = map.getView().getZoom();
        map.on('moveend', function() {
            var current_zoom = map.getView().getZoom();
            var category = 'Radar';
            if($('#plan-your-route-container').length > 0) {
                category = 'Route planner';
            }
            if(last_zoom > current_zoom) {
                window.ga('send', 'event', { eventCategory: category, eventAction: 'Zoom out', eventLabel: 'Click'});
            } else if(last_zoom < current_zoom) {
                window.ga('send', 'event', { eventCategory: category, eventAction: 'Zoom in', eventLabel: 'Click'});

            }
            last_zoom = current_zoom;

            map_container.find('.ol-zoom-out, .ol-zoom-in').removeClass('disabled');
            if(current_zoom <= min_zoom) {
                map_container.find('.ol-zoom-out').addClass('disabled');
            }
            if(current_zoom >= max_zoom) {
                map_container.find('.ol-zoom-in').addClass('disabled');
            }
        });

        if(!data.allow_drag) {
            map_container.addClass('disable-pan');
            container.find('.ol-viewport').css('touch-action', '');
        }

        var all_markers = [];
        var addMarker = function(coordinates, time, temp, icon, active, link) {
            var pin = window.config.map_pin;
            if(active) {
                pin = window.config.map_pin_active;
            }
            var map_overlay = $('<a href="' + link + '" class="map-poi-info"><img src="' + pin + '" class="pin"><div class="time">' + time + '</div><div class="info">' + icon + ' ' + temp + '</div></a>');
            var marker = new ol.Overlay({
                positioning: 'bottom-left',
                position: ol.proj.transform(coordinates, 'EPSG:4326', 'EPSG:3857'),
                element: map_overlay.get(0)
            });
            all_markers.push(marker);
            map.addOverlay(marker);
        };

        var removeAllMarker = function() {
            $.each(all_markers, function(key, value) {
                map.removeOverlay(value);
            });
            all_markers = [];
        };

        addMarker(coordinates, data.overlay_data.time, data.overlay_data.temp, data.overlay_data.icon, false, data.overlay_data.link);

        $.each(data.locations_nearby, function(key, value) {
            var marker_coordinates = [value.coordinate.lon, value.coordinate.lat];
            addMarker(marker_coordinates, value.time, value.weather.temp, value.weather.icon, true, value.link);
        });

        var routeLayer = null;
        var addRoute = function(data) {
            removeAllMarker();
            if(routeLayer !== null) {
                map.removeLayer(routeLayer);
            }
            var vectorSource = new ol.source.Vector({});
            routeLayer = new ol.layer.Vector({
                source: vectorSource, style: [new ol.style.Style({
                    stroke: new ol.style.Stroke({
                        color: '#222',
                        width: 2
                    })
                })]
            });
            map.addLayer(routeLayer);

            var lineString = new ol.geom.LineString(data.shape);
            lineString.transform('EPSG:4326', 'EPSG:3857');
            var feature = new ol.Feature({ geometry: lineString, name: 'Route'});
            vectorSource.addFeature(feature);

            var ext = ol.extent.boundingExtent(data.bounding_box);
            ext = ol.proj.transformExtent(ext, ol.proj.get('EPSG:4326'), ol.proj.get('EPSG:3857'));
            map.getView().fit(ext, map.getSize());

            $.each(data.marker_list, function(key, value) {
                addMarker(value.coordinates, value.time, value.info.temp, value.info.icon, false, value.link);
            });
        };

        $(this).on('update-route-data', function(event, data) {
            addRoute(data);
        });

        container.find('.change-map-overlay').on('click', function() {
            addRadarLayer(container, map, data.elements[$(this).data('type')]);
            return false;
        });

        $(window).on('resize', function() {
            map.updateSize();
        });

        if(typeof data.elements !== 'undefined') {
            addRadarLayer(container, map, data.elements[data.active_layer]);
        }

        var toggleTimelineAnimation = function (set_running) {
            var button = container.find('.timeline-play-btn');
            if(!set_running) {
                window.ga('send', 'event', { eventCategory: 'Radar', eventAction: 'Pause', eventLabel: 'Click'});
                container.removeClass('is-running');
                animation_running = false;
                clearInterval(updateInterval);
                button.removeClass('animation-running');
            } else {
                window.ga('send', 'event', { eventCategory: 'Radar', eventAction: 'Play', eventLabel: 'Click'});
                container.addClass('is-running');
                button.addClass('animation-running');
                animation_running = true;
                startAnimation();
            }
        };

        container.find('.timeline-play-btn').on('click', function() {
            toggleTimelineAnimation(!animation_running);
        });

        $('.video-modal').on('shown.bs.modal', function () {
            toggleTimelineAnimation(false);
        });
    });


}(jQuery));
